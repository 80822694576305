
import MoleculePin, {
  DefaultPinBoxHeight,
  DefaultPinBoxWidth
} from '~/components/molecules/project/MoleculePin.vue'
import { ProjectFocusMode } from '~/store/building/-constants'
import { generateDeterministicItem, orderQueryParamsByGivenOrderList } from '~/helpers/util'
import availabilityConstants from '~/store/availability/-constants'
import shareConstants from '~/store/share/-constants'

export default {
  name: 'OrganismPins',
  components: { MoleculePin },
  props: {
    pinConfig: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    isV2: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      pinsData: [],
      expandedPin: -1
    }
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.default()
    },
    projectFocusMode() {
      return this.$store.state.building.projectFocusMode
    },
    canvasDimensions() {
      return this.clientManager.getCanvasDimensions()
    },
    isBuildingFocus() {
      return this.projectFocusMode === ProjectFocusMode.BUILDING
    },
    activePin() {
      return this.$store.state?.share?.activePin || undefined
    }
  },
  watch: {
    pinConfig: {
      deep: true,
      handler: function (newPinConfig) {
        this.pinsData = newPinConfig.pins
      }
    }
  },
  mounted() {
    if (this.pinConfig) {
      this.pinsData = this.pinConfig.pins

      if (!this.pinsData?.length || typeof this.activePin !== 'undefined' || !this.isV2) return
      this.clientManager.onSceneReady = () => {
        this.clientManager.onAfterRender(async () => {
          for (let i = 0; i < this.pinsData.length; i++) {
            const requiredPinDataEntries = { ...this.pinsData[i] }
            if (typeof requiredPinDataEntries?.backgroundColor !== 'undefined') {
              delete requiredPinDataEntries.backgroundColor
            }
            const deterministicPin = await generateDeterministicItem(requiredPinDataEntries)
            if (deterministicPin === this.activePin) {
              this.handlePinExpand(i)
              break
            }
          }
        }, 100)
      }
    }

    this.clientManager.onUpdatePinPosition = (pinState) => {
      requestAnimationFrame(() => this.handleUpdatePinPosition(pinState))
    }
    this.clientManager.onUpdatePinVisibility = (pinState) => {
      requestAnimationFrame(() => this.handleUpdatePinVisibility(pinState))
    }
  },
  methods: {
    handleUpdatePinPosition(pinsState) {
      for (let i = 0; i < this.pinsData.length; i++) {
        const pinState = pinsState?.[i]
        const componentRef = this.$refs.pinRef?.[i]?.$el
        if (componentRef) {
          const { x, y } =
            i === this.expandedPin
              ? this.updateDivPosition(componentRef, pinState.position_2d)
              : pinState.position_2d
          componentRef.style.transform = `translateX(${x}px) translateY(${y}px)`
        }
      }
    },
    async handleUpdatePinVisibility(pinsState) {
      for (let i = 0; i < this.pinsData.length; i++) {
        const pinState = pinsState?.[i]
        const componentRef = this.$refs.pinRef?.[i]?.$el

        if (componentRef) {
          componentRef.style.display = pinState.visibility ? 'inline-block' : 'none'
          if (i === this.expandedPin && !pinState.visibility) {
            this.expandedPin = -1
          }
        }
      }
    },
    handlePinExpand(index) {
      this.expandedPin = index
      this.clientManager?.forceUpdatePinAndLabelPositions()
    },
    async handlePinExpandWithDeterministicItem(index) {
      if (this.isV2) {
        const requiredPinDataEntries = { ...this.pinsData[index] }
        if (typeof requiredPinDataEntries?.backgroundColor !== 'undefined') {
          delete requiredPinDataEntries.backgroundColor
        }
        const deterministicPin = await generateDeterministicItem(requiredPinDataEntries)
        this.$store.dispatch(
          shareConstants.withNamespace(shareConstants.action.UPDATE_SHARE_STATE_PARAMS),
          {
            activePin: deterministicPin
          }
        )

        const query = {
          ...this.$route.query,
          pin: deterministicPin,
          ...(window.innerWidth < 1200 && { pinCategory: this.pinsData[index].category }) // set the category for lower resolutions to be able to have a perfect share link
        }
        const orderedQuery = orderQueryParamsByGivenOrderList(query)
        this.$router.push({ query: { ...orderedQuery } })
      }
      this.handlePinExpand(index)
    },
    handlePinClose() {
      this.expandedPin = -1
      this.clientManager?.forceUpdatePinAndLabelPositions()

      if (this.isV2) {
        this.$store.dispatch(
          shareConstants.withNamespace(shareConstants.action.UPDATE_SHARE_STATE_PARAMS),
          {
            activePin: null
          }
        )
        const query = { ...this.$route.query }
        if (typeof query?.pin !== 'undefined') {
          delete query.pin
          if (window.innerWidth < 1200 && typeof query?.pinCategory !== 'undefined') {
            delete query.pinCategory
          }
        }
        const orderedQuery = orderQueryParamsByGivenOrderList(query)
        this.$router.push({ query: { ...orderedQuery } })
      }
    },
    remToPixels(rem, fontSizeVw) {
      const fontSizePx = (fontSizeVw / 100) * window.innerWidth
      return rem * fontSizePx
    },
    updateDivPosition(div, position2d) {
      const [canvasWidth, canvasHeight] = this.canvasDimensions

      const compound = getComputedStyle(div)
      const divMinWidth = DefaultPinBoxWidth * parseFloat(compound.fontSize) + 10
      const divMinHeight = DefaultPinBoxHeight * parseFloat(compound.fontSize) + 10

      const divWidth = Math.max(div.offsetWidth, divMinWidth)
      const divHeight = Math.max(div.offsetHeight, divMinHeight)

      let adjustedX = position2d.x
      let adjustedY = position2d.y

      if (position2d.x + divWidth / 2 > canvasWidth) {
        adjustedX = canvasWidth - divWidth / 2
      }
      if (position2d.y + divHeight / 2 > canvasHeight) {
        adjustedY = canvasHeight - divHeight / 2
      }
      if (position2d.x - divWidth / 2 < 0) {
        adjustedX = divWidth / 2
      }
      if (position2d.y - divHeight / 2 < 0) {
        adjustedY = divHeight / 2
      }

      return { x: adjustedX, y: adjustedY }
    }
  }
}
